<template>
    <div class="ui grid container-4" id="bcmmber">
      <div class="intro-content">
        <h1><span>Become a Member in </span>3 Easy Steps.</h1>
        <h2><span>All you need is your </span>mobile phone.</h2>

        <div class="item">
          <p class="first"><span class="bold-txt">Step 1.</span> <span>Scan the QR</span> to download the TayoCash app.</p>

          <div class="qr">
            <a href="https://apps.apple.com/ph/app/tayocash/id1547566625" target="_blank"><img class="qr-code" src="@/assets/qr-ios.png" alt="Tayocash">
              <span>iOS</span>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.tayocash" target="_blank"><img class="qr-code" src="@/assets/qr-android.png" alt="Tayocash">
              <span>Android</span>
            </a>
          </div>
        </div>

        <div class="item">
          <p><span class="bold-txt">Step 2.</span> Enter your <span>Mobile Number</span> and <span>Full Name</span> to create an account.</p>
        </div>

        <div class="item">
          <p><span class="bold-txt">Step 3.</span> You can now access and start using your TayoCash e-wallet!</p>
        </div>

        <div class="item">
          <p>Learn more about how to maximize your TayoCash e-wallet by clicking below.</p>
        </div>

        <div class="item links">
          <a class="yellow" href="/faq/verification">HOW TO VERIFY ACCOUNT</a>
          <a class="white" href="/faq/services">HOW TO CASH IN</a>
        </div>
      </div>
      <div class="image-div">
        <img class="people" src="@/assets/md4.svg">
      </div>
    </div>
</template>

<script>
export default {
  name: 'content4'
}
</script>

<style scoped lang='scss'></style>
